@use '@ingka/base/_mixins.scss' as *;
@import '../../../constants.scss';

.#{$prefix}sheet__content {
  height: 100%;
}

.profilePagesIframe {
  border: none;
  width: 100%;
  height: 100%;
}

#menuModal {
  z-index: 99999999999;
}

.menuModalBodyContainer {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  width: 100%;
  height: 100%;
  min-height: 80vh;
  background-color: $colour-elevation-2;

  & > * {
    grid-row: 1;
    grid-column: 1;
  }
}

.skapasModalBody {
  height: 100%;
  z-index: 9999999;
  padding-top: 0 !important;
}

